<template>
    <article :style="cardStyle()" class="card w-100">

        <!-- TITLE -->
        <h1 class="clean-text-css desktop-element" :style="titleStyle()" v-html="article.title"></h1>
        <h1 class="clean-text-css mobile-element" :style="titleStyle(false)" v-html="article.title"></h1>

        <!-- POST INFO -->
        <div  :class="`post-info ${!show.author && 'only-social-bar'}`">
            <div
                    class="author-content"
                    v-show="show.author">
                <img :src=" article.author.thumbnail" width="70px" height="70px" />
                <div class="author-content-info">
         <a :href="`/a/${article.author.slug}`"  :style="authorStyle()" class="author-name clean-text-css desktop-element">
            {{ article.author.name }}
            </a>

                    <a :href="`/a/${article.author.slug}`" :style="authorStyle(false)" class="author-name  clean-text-css  mobile-element">
              {{ article.author.name }}
            </a>
            <span v-if="show.date" :style="authorStyle()" class="post-date desktop-element"> </span>
            <span v-if="show.date" :style="authorStyle(false)" class="post-date mobile-element"> </span>
                </div>
            </div>
            <social-bar v-show="show.author" :icons="article.author" />
        </div>
        <div v-if="!+article.is_capture || is_logged" class="post-content content-post ql-editor" v-html="article.content" />
        <div v-else>
            <div>
                <div class="post-content content-post ql-editor" v-html="article.message_capture" />
                <div class="post-content content-post ql-editor" v-html="capture.content" />

                <div :style="captureDivStyle()">
                    <a
                        :href="`/blog-login?post=${article.url}`"
                        :style="captureRegisterButton()"

                    >{{capture.register_button_settings.text }}</a>
                    <a
                        :href="`/blog-login?post=${article.url}`"
                        :style="captureLoginButton()"
                    >{{ capture.login_button_settings.text }} </a>
                </div>
            </div>
        </div>
        <div v-show="show.shareBar.display" :style="shareBarDivStyle()" class="share-bar">
            <span v-show="show.shareBar.copy" @click="copyCLipBoard"  :style="shareButtonStyle()" class="post-share-icon fas fa-link"></span>
            <a v-show="show.shareBar.email"  :href="showUrl('email')" target="_blank"  :style="shareButtonStyle()" class="post-share-icon far fa-envelope"></a>
            <a v-show="show.shareBar.facebook" :href="showUrl(`facebook`)" target="_blank"  :style="shareButtonStyle()" class="post-share-icon fab fa-facebook-f"></a>
            <a v-show="show.shareBar.twitter" :href="showUrl('twitter')" target="_blank"  :style="shareButtonStyle()" class="post-share-icon icon-x">
                <!-- current version of font awesome does not have x icon-->
                <svg width="30" height="30" viewBox="0 0 30 30" fill="currentColor" xmlns="http://www.w3.org/2000/svg" style="fill: #fff;width: 22px;height: 22px;"><path d="M20.75 3h3.065l-6.699 10.167L24.998 27h-6.17l-4.832-8.39-5.531 8.388h-3.07l7.166-10.873L5 3h6.328l4.368 7.668L20.75 3Zm-1.078 21.564h1.7L10.405 5.31H8.58l11.092 19.254Z"></path></svg>
            </a>
            <a v-show="show.shareBar.pinterest" :href="showUrl('pinterest')" target="_blank"  :style="shareButtonStyle()" class="post-share-icon fab fa-pinterest"></a>
            <a v-show="show.shareBar.linkedin" :href="showUrl('linkedin')" target="_blank"  :style="shareButtonStyle()" class="post-share-icon fab fa-linkedin-in"></a>
            <span class="mobile-element">
        <a v-show="show.shareBar.whatsapp"  :href="showUrl('whatsapp')" target="_blank" :style="shareButtonStyle()" class="post-share-icon fab fa-whatsapp"></a>
        <a v-show="show.shareBar.telegram" :href="showUrl('telegram')" target="_blank" :style="shareButtonStyle()" class="post-share-icon fab fa-telegram"></a>
      </span>

        </div>
        <!-- CATEGORIES -->
        <div :style="categoryDivStyle()" class="categories-info" v-show="show.categories">
            <a :href="`/c/${category.url}`" v-for="category in article.categories" :key="category.url" :style="categoryStyle()">{{ category.name }}</a>
        </div>

        <!-- AUTHOR INFO -->
        <div class="post-info" v-show="show.author">
            <div class="author-content">
                <img :src=" article.author.thumbnail" width="70px" height="70px" />
                <div class="author-info">
          <a :href="`/a/${article.author.slug}`" :style="authorStyle()" class="author-name clean-text-css desktop-element">
           {{ article.author.name }}
            </a>

                    <a :href="`/a/${article.author.slug}`" :style="authorStyle(false)" class="author-name  clean-text-css  mobile-element">
           {{ article.author.name }}
            </a>
                </div>
            </div>
            <!--      <a class="author-profile" :href="`/a/${article.author.slug}`">Filter</a>-->
        </div>

        <comments v-if="article.enabled_comments" :is_logged="is_logged" :article="article" ></comments>
    </article>
</template>

<script>

    import SocialBar from '../../Generic/SocialBarSimple';
    import Comments from './_part/Comments';
    import moment from 'moment/moment';

    export default {
        name: 'Simple',
        props: ['article', 'show', 'colors', 'styles', 'capture', 'is_logged', 'display_date'],
        components: {
            SocialBar, Comments
        },
        data() {
            return {
                title: ''
            }
        },
        methods: {
            captureDivStyle() {
                return `display:  ${this.capture.theme.display};
                  flex-direction: ${this.capture.theme.flexDirection};
                  align-items: ${this.capture.theme.alignItems};
                  justify-content: ${this.capture.theme.justifyContent};`
            },
            captureLoginButton() {
                return `font-family: ${this.capture.login_button_settings.style.fontFamily};
                  font-size: ${this.capture.login_button_settings.style.fontSize};
                    background-color: ${this.capture.login_button_settings.style.background};
                  color: ${this.capture.login_button_settings.style.color};
                  border-radius: ${this.capture.login_button_settings.style.borderRadius};
                  padding: ${this.capture.login_button_settings.style.padding};
                  margin: ${this.capture.login_button_settings.style.margin};
                  `
            },
            captureRegisterButton() {
                    return `font-family: ${this.capture.register_button_settings.style.fontFamily};
                  font-size: ${this.capture.register_button_settings.style.fontSize};
                  background-color: ${this.capture.register_button_settings.style.background};
                  color: ${this.capture.register_button_settings.style.color};
                  border-radius: ${this.capture.register_button_settings.style.borderRadius};
                  padding: ${this.capture.register_button_settings.style.padding};
                  margin: ${this.capture.register_button_settings.style.margin};
                  `
            },
            showUrl(type) {
                let vm = this;
                if (typeof vm.article.date_cycle !== 'undefined' && window.location.hash.indexOf('#/editor/') === -1) {
                  if(type === 'facebook') {
                      return `https://www.facebook.com/sharer/sharer.php?u=${encodeURI(window.location.href)}`;
                  }

                  if(type === 'twitter') {
                      return `https://twitter.com/intent/tweet?text=${this.article.title}&url=${encodeURI(window.location.href)}`
                  }

                  if(type === 'linkedin') {
                      return `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURI(window.location.href)}`
                  }

                  if(type === 'pinterest') {
                      return `https://pinterest.com/pin/create/button/?url=${encodeURI(window.location.href)}`
                  }

                  if(type === 'whatsapp') {
                      return `whatsapp://send?text=${this.article.title} - ${encodeURI(window.location.href)}`
                  }

                    if (type === 'email') {
                        return `mailto:?subject=${this.article.title}&body=${encodeURI(window.location.href)}`;
                    }

                    if (type === 'telegram') {
                        return `tg://msg?text=${this.article.title}`;
                    }
                } else {
                    return '#';
                }
            },
            copyCLipBoard() {
                let dummy = document.createElement('input'),
                    text = window.location.href;

                document.body.appendChild(dummy);
                dummy.value = text;
                dummy.select();
                document.execCommand('copy');
                document.body.removeChild(dummy);
            },
            formatDate(date, format) {
                const locale = window.navigator.userLanguage || window.navigator.language;
                moment.locale(locale)

                return moment.utc(String(date)).local().format(format);
            },
            cardStyle() {
                return `background-color: ${this.colors.color_1}; padding: 15px`;
            },
            shareBarDivStyle() {
                let align = this.show.shareBar.align === 'left' ? 'flex-start' : this.show.shareBar.align === 'right' ? 'flex-end' : 'center';
                return `display: flex; justify-content: ${align}; flex-wrap: wrap;`;
            },
            shareButtonStyle() {
                let prop = (this.show.shareBar.theme === 'circle') ? 'border-radius: 50%;' : '';
                return prop;
            },
            categoryDivStyle() {
                let align = this.styles.categories.align === 'left' ? 'flex-start' : this.styles.categories.align === 'right' ? 'flex-end' : 'center';
                return `margin-top: 10px; display: flex; justify-content: ${align}; flex-wrap: wrap`;
            },
            titleStyle(desktop = true) {
                return `font-family:  ${this.styles.title.family};
                  font-size: ${(desktop) ? this.styles.title.size : this.styles.title.sizeMobile} !important;
                  text-align: ${this.styles.title.align};
                  font-weight: ${this.styles.title.weight};
                  font-style: ${this.styles.title.style};
                  text-decoration: ${this.styles.title.stroke};
                  color: ${this.styles.title.color}`;
            },
            authorStyle(desktop = true) {
                return `font-family:  ${this.styles.author.family};
                  font-size: ${(desktop) ? this.styles.author.size : this.styles.author.sizeMobile} !important;
                  text-align: ${this.styles.author.align};
                  font-weight: ${this.styles.author.weight};
                  font-style: ${this.styles.author.style};
                  text-decoration: ${this.styles.author.stroke};
                  color: ${this.styles.author.color}`;
            },
            categoryStyle(desktop = true) {
                return `font-family:  ${this.styles.categories.family};
                  font-size: ${(desktop) ? this.styles.categories.size : this.styles.categories.sizeMobile} !important;
                  text-align: ${this.styles.categories.align};
                  font-weight: ${this.styles.categories.weight};
                  font-style: ${this.styles.categories.style};
                  text-decoration: ${this.styles.categories.stroke};
                  color: ${this.styles.categories.color};
                  background-color: ${this.colors.color_3};
                  margin-top: 5px`;
            }
        },
        mounted() {
          this.title = this.article.title;
        },
        watch: {
            display_date() {
                this.title = this.article.title;
            }
        }
    };
</script>

<style lang="scss" scoped>

    [data-device="mobile"] {
      .post-info {
        flex-direction: column;
      }
    }

    .post-share-icon{
      cursor: pointer;
    }
    .post-info {
        display: flex;
        justify-content: space-between;
        align-items: center;

        margin: 30px 0px 50px 0px;

        &.only-social-bar {
            justify-content: flex-end;
        }

        a.author-profile {
            max-height: 35px;

            color: rgba(0, 0, 0, 0.54);
            border: 1px solid rgba(0, 0, 0, 0.54);
            padding: 5px;
            border-radius: 5px;
            text-decoration: none;
            transition: box-shadow 0.2s;

            &:hover {
                box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.54);

            }
        }
    }

    .author-content {
        display: flex;

        img {
            border-radius: 50%;
        }

        &-info {
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin-left: 10px;

            .author-name {
                color: rgba(#000000, 0.84);
            }

            .post-date {
                color: rgba(#000000, 0.54);

            }
        }
    }

    .post-content {
        img {
            width: 100%;
        }
    }

    .categories-info {
        line-height: 45px;

        span {
            padding-left: 10px;
            padding-right: 10px;
            border-radius: 2px;
            margin-right: 3px;
            color: rgba(#000000, 0.54);
            background-color: rgba(#000000, 0.25);
        }
    }

</style>

<style scoped>
    /* Style all font awesome icons */
    .post-share-icon {
        margin-top: 5px;
        margin-left: 2px;
        padding: 15px;
        font-size: 20px;
        width: 50px;
        height: 50px;
        text-align: center;
        text-decoration: none;
    }

    /* Add a hover effect if you want */
    .post-share-icon:hover {
        opacity: 0.7;
    }

    /* Set a specific color for each brand */

    /* Facebook */
    .fa-facebook-f {
        background: #3B5998;
        color: white;
    }

    /* Twitter */
    .icon-x {
        background: #12100e;
        color: white;
        display: flex;
        justify-content: center;
    }

    /* Instagram */
    .fa-instagram {
        background: #d00174;
        color: white;
    }

    /* Linkedin */
    .fa-linkedin-in {
        background: #2867b2;
        color: white;
    }

    /* Youtube */
    .fa-pinterest {
        background: #ff0000;
        color: white;
    }

    /* Medium */
    .fa-medium-m {
        background: #12100e;
        color: white;
    }

    /* Email */
    .fa-envelope {
        background: #363636;
        color: white;
    }

    /* Copy */
    .fa-link {
        background: #363636;
        color: white;
    }

    /* whatsapp */
    .fa-whatsapp {
        background: #25D366;
        color: white;
    }

    /* telegram */
    .fa-telegram {
        background: #0088cc;
        color: white;
    }
</style>
